.tabs input[name=tab-control] {
    display: none;
}

.tabs ul {
    list-style-type: none;
    padding-left: 0;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    margin-bottom: 0px;
}

.tabs ul li {
    box-sizing: border-box;
    padding: 0 5px;
    text-align: center;
    position: relative;
}


.tabs ul li label a {
    font-weight: bold;
    font-size: 17px;

    transition: all 0.3s ease-in-out;
    color: #929daf;
    padding: 5px auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tabs ul li label a:hover,
.tabs ul li label a:active {
    outline: 0;
    color: #bec5cf;
}

.tabs ul li label a::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    background-color: transparent;
}

.tabs .slider {
    position: relative;
    margin-top: -4px;
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}

.tabs .slider .indicator {
    position: relative;
    width: 50px;
    max-width: 100%;
    margin: 0 auto;
    height: 4px;
    background: var(--tl-accent-color);
    border-radius: 1px;
}

.tabs ul li label a.active {
    color: var(--tl-accent-color);
}

.tabs ul li label a.sub-tab {
    height: 46px;
    font-size: 20px;
}

@media (max-width: 1100px) {
    .tabs ul li label a {
        font-size: 15px;
    }

    .tabs ul li {
        padding: 0;
    }
}