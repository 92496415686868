:root {
  color-scheme: dark !important;
  --tl-font-family: Montserrat, Roboto, sans-serif;
  --tl-accent-color: #20bf95;
  --tl-accent-color-hover: #0d765a;
  --tl-btn-primary-text-color: rgb(36, 39, 40);
  --tl-text-color: rgba(225, 225, 222, 0.85);
  --tl-card-color: #1d2632;
  --tl-card-color-hover: #242f3d;
  --tl-card-border-color: #30404d;
  --tl-modal-color: #1d2632;
  --tl-popover-color: #30404d;
  --tl-badge-color: #21bf94;
  --tl-footer-height: 60px;
  --tl-in-progress-color: orange;
}

p {
  margin-bottom: 0px;
}

a {
  color: var(--tl-accent-color);
}

a:hover {
  color: var(--tl-accent-color-hover);
}

img {
  display: block;
}

.ant-divider {
  border-color: var(--tl-card-border-color);
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top-color: var(--tl-card-border-color);
}

.ant-divider-inner-text {
  font-size: 24px;
  font-weight: 600;
}

.ant-btn,
.ant-btn:focus {
  border-radius: 0.5rem;
  border-color: var(--tl-accent-color);
  color: var(--tl-accent-color);
}

.ant-btn-background-ghost.ant-btn-primary,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: var(--tl-accent-color);
  border-color: var(--tl-accent-color);
}

.ant-btn:hover,
.ant-btn-background-ghost.ant-btn-primary:hover {
  color: var(--tl-accent-color-hover);
  border-color: var(--tl-accent-color-hover);
}

.ant-btn-primary,
.ant-btn-primary:focus {
  color: var(--tl-btn-primary-text-color);
  background: var(--tl-accent-color);
  border-color: var(--tl-accent-color);
}

.ant-btn-primary:hover {
  color: var(--tl-btn-primary-text-color);
  background: var(--tl-accent-color-hover);
  border-color: var(--tl-accent-color-hover);
}

.ant-modal {
  font-family: var(--tl-font-family);
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
  background-color: var(--tl-modal-color);
}

.ant-modal .ant-picker-clear,
.ant-modal .ant-slider-handle,
.ant-modal .ant-anchor-wrapper,
.ant-modal .ant-collapse-content,
.ant-modal .ant-timeline-item-head,
.ant-modal .ant-card {
  background: var(--tl-modal-color);
  border-color: var(--tl-card-border-color);
}

.ant-modal.close-icon-hidden>.ant-modal-content>.ant-modal-close {
  display: none;
}

.ant-card {
  color: var(--tl-text-color);
  background: var(--tl-card-color);
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px);
  border: 0px;
}

.ant-card-body {
  padding: 6px 10px 15px 10px;
}

.ant-card-actions {
  border-top-color: var(--tl-card-border-color);
  border-top-width: 2px;
  background: var(--tl-card-color);
}

.ant-card-actions>li:not(:last-child) {
  border-right: 2px solid var(--tl-card-border-color);
}

.ant-card-meta-detail {
  text-align: center;
}

.ant-card-actions>li>span {
  line-height: 2;
}

.ant-card-actions>li>span:hover,
.ant-card-actions>li>span a:not(.ant-btn):hover,
.ant-card-actions>li>span>.anticon:hover {
  color: var(--tl-accent-color);
}

.ant-card-hoverable:hover,
.ant-card-hoverable:hover .ant-card-actions {
  background: var(--tl-card-color-hover);
}

.ant-card-hoverable.tl-constructing-item-card:hover,
.ant-card-hoverable.tl-constructing-item-card:hover .ant-card-actions {
  background: var(--tl-card-color-hover);
  border: 2px dashed var(--tl-in-progress-color);
}

.ant-card-head {
  padding: 14px;
  border: none;
  position: absolute;
  z-index: 2;
  width: 100%;
}

.ant-card-extra {
  padding: 0px;
  font-size: 18px;
  color: var(--tl-text-color);
  width: 100%;
}

.ant-card-extra>div>span,
.card-button {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(48 64 77 / 75%);
  border-radius: 0.5rem;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease;
}

.ant-card-hoverable:hover .ant-card-extra>div>span,
.ant-card-hoverable:hover .card-button {
  visibility: visible;
  opacity: 1;
}

.ant-card-extra>div>span.card-button:hover,
.ant-card-extra>div>div.card-button:hover {
  background: rgb(48 64 77);
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px);
}

.ant-typography {
  color: var(--tl-text-color);
}

.ant-skeleton-element {
  width: inherit;
}

.ant-skeleton-element.tl-card-skeleton .ant-skeleton-avatar {
  width: 330px;
  height: 330px;
  border-radius: 1rem;
}

.ant-skeleton-element.tl-builder-step-skeleton .ant-skeleton-avatar {
  height: 74px;
  width: -webkit-fill-available;
  border-radius: 0.5rem;
}

.ant-skeleton-element.tl-builder-item-skeleton .ant-skeleton-avatar {
  height: 68px;
  width: -webkit-fill-available;
  border-radius: 0.5rem;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph>li {
  background: transparent;
}

.tl-builder-item-skeleton.ant-skeleton-active .ant-skeleton-avatar::after {
  background: linear-gradient(90deg, rgb(190 190 190 / 10%) 25%, rgba(255, 255, 255, 0.16) 37%, rgb(190 190 190 / 10%) 63%);
}

.ant-modal-footer {
  border-top: 0px;
  padding-bottom: 16px;
}

.nft-modal>.ant-modal-content>.ant-modal-footer {
  display: flex;
  justify-content: end;
}

.ant-alert {
  font-size: 16px;
  border-radius: 1rem;
  padding: 16px 20px;
  animation: appear 0.5s ease-in 0.2s forwards;
  opacity: 0;
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px);
}

.ant-alert-message {
  color: var(--tl-text-color);
}

.ant-alert-info {
  background-color: transparent;
  border: 1px solid #187ddc;
}

.ant-alert-success {
  background-color: transparent;
  border: 1px solid #4aaa1a;
}

.ant-alert-warning {
  background-color: transparent;
  border: 1px solid #d89612;
}

.ant-alert-error {
  background-color: transparent;
  border: 1px solid #a61d24;
}

.ant-image-preview-img {
  display: initial;
}

.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.9);
}

.ant-image-preview-operations {
  width: auto;
  background: none;
}

.ant-card-actions>li>span>.anticon {
  line-height: 26px;
  font-size: 20px;
}

.ant-tooltip,
.ant-popover {
  font-family: var(--tl-font-family);
}

.ant-tooltip-inner,
.ant-popover-inner {
  background-color: #384e56;
  border-radius: 0.5rem;
  font-size: 16px;
  text-align: center;
}

.ant-tooltip-arrow-content,
.ant-popover-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(56, 78, 86, 0.9), #384e56);
}

.tl-builder-item-popover .ant-popover-inner {
  background-color: var(--tl-card-color-hover);
}

.tl-builder-item-popover .ant-popover-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(29, 38, 50, 0.9), var(--tl-card-color-hover));
}

.ant-image-mask,
.ant-image-mask:hover {
  border-radius: 1rem;
}

.ant-message {
  font-family: var(--tl-font-family);
  font-size: 16px;
  line-height: 1.6;
  margin-top: 60px;
}

.ant-message .anticon {
  font-size: 20px;
}

.ant-message-notice-content {
  border-radius: 40px;
  background: var(--tl-card-color-hover);
}

.ant-badge {
  line-height: inherit;
}

sup[title].ant-badge-count {
  pointer-events: none;
}

.ant-tag {
  border-radius: 0.7rem;
  text-align: center;
  padding: 6px 14px;
  margin-bottom: 8px;
  cursor: pointer;
  border-width: 1px;
  user-select: none;
  border: 1px solid #5e5d5d;
  transition: all .5s ease;
}

.ant-tag:active {
  transform: scale(.96);
  transition: all 0.1s ease;
}

.ant-tag-magenta {
  border-color: #913065;
}

.ant-tag-orange {
  border-color: #a76c2d;
}

.ant-tag-gold {
  border-color: #a97f29;
}

.ant-tag-lime {
  border-color: #546b1a;
}

.ant-tag-green {
  border-color: #5aa733;
}

.ant-tag-cyan {
  border-color: #248888;
}

.ant-tag-blue {
  border-color: #5d69be;
}

.ant-tag-geekblue {
  border-color: #32469a;
}

.ant-tag-purple {
  border-color: #623e95;
}

.ant-tag-red {
  border-color: #9d2b32;
}

.ant-tag-volcano {
  border-color: #a34728;
}

.ant-input-number {
  border-radius: 0.5rem;
}

.ant-input-number-handler-wrap {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: var(--tl-card-color);
}

.ant-input-number-handler-up {
  border-top-right-radius: 0.5rem;
}

.ant-input-number-handler-down {
  border-bottom-right-radius: 0.5rem;
}

span.anticon.anticon-wallet {
  width: 100%;
  height: 24px;
}


.ant-steps {
  gap: 14px;
}

.ant-steps-item-container {
  padding: 10px;
  height: 100%;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #13ae85;
}

.ant-steps-item-title {
  font-weight: 600;
  margin-right: 30px;
  white-space: normal;
}

.ant-steps-item-title::after {
  height: 0px;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--tl-accent-color);
  border-color: var(--tl-accent-color);
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon,
.ant-steps .ant-steps-item.ant-steps-item-finish>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon,
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: var(--tl-card-color);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: transparent;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--tl-accent-color);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon,
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process):not(.ant-steps-item-finish)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: var(--tl-accent-color);
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: var(--tl-accent-color);
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: rgba(255, 255, 255, 0.45);
}

.ant-steps-item:last-child {
  flex: 0% 1 1;
}

.ant-list-empty-text {
  font-size: 18px;
  margin-top: 120px;
}

.ant-list-item-meta-avatar {
  margin-right: 0px;
}

.tl-body {
  overflow: hidden;
}

.tl-body.normal {
  background-color: #393955;
}

.tl-body.darken {
  background-color: #2c2d43;
}

.tl-send-or-buy-nft-modal>.ant-modal-content>.ant-modal-footer {
  text-align: center;
  display: flex;
  justify-content: right;
  padding: 0px 24px 24px 24px;
}

.tl-button-loading>button,
button.tl-button-loading {
  display: flex;
  transition: all .3s ease;
}

button.tl-button-darken-on-hover:hover {
  opacity: 50%;
}

.tl-balance-container {
  color: var(--tl-text-color);
  display: flex;
  font-size: 17px;
  font-weight: bold;
}

.tl-balance-icon {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 6px;
}

.tl-dynamic-balance-amount {
  font-size: 16px;
  margin-top: 1px;
}

.tl-balance-amount {
  font-size: 20px;
  margin-top: 1px;
}

.tl-solid-button {
  border: 1px solid;
  font: inherit;
  font-weight: normal;
  line-height: 1;
  padding: 13px 20px;
  border-radius: 10px;
  cursor: pointer;
  width: max-content;

  background: var(--tl-accent-color);
  box-shadow: inset 0 0 0 2em var(--tl-accent-color);
  border-color: var(--tl-accent-color);
  color: #363b40;
  transition: all .5s ease;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.tl-solid-button:hover,
.tl-solid-button.hovered {
  background: none;
  box-shadow: none;
  border-color: var(--tl-accent-color);
  color: var(--tl-accent-color);
  transition: all .5s ease;
}

.tl-solid-button:active {
  transform: scale(.96);
  transition: all 0.1s ease;
}

.tl-solid-button:focus {
  outline: none;
}

.tl-solid-button.inverted {
  padding: 10px 14px;
  background: none;
  box-shadow: none;
  border-color: var(--tl-accent-color);
  color: var(--tl-accent-color);
  transition: all .5s ease;
}

.tl-solid-button.inverted:hover,
.tl-solid-button.inverted.hovered {
  background: var(--tl-accent-color);
  box-shadow: inset 0 0 0 2em var(--tl-accent-color);
  border-color: var(--tl-accent-color);
  color: #363b40;
  transition: all .5s ease;
}

.tl-solid-button.inverted:active {
  transition: all 0.1s ease;
}

.tl-default-item-card {
  border: none;
}

.tl-owned-item-card,
.tl-owned-item-card:hover {
  border: 1px solid #3fd5ac;
}

.tl-constructing-item-card {
  border: 2px dashed var(--tl-in-progress-color);
}

.tl-constructing-item-modal-image {
  border: 2px dashed var(--tl-in-progress-color);
  border-radius: 1rem;
}

.tl-constructing-item-modal-subtitle {
  color: var(--tl-in-progress-color) !important;
}

.tl-props-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

@media only screen and (max-width: 1060px) {
  .tl-dynamic-balance-container {
    display: none;
  }
}

.first.header-section,
.last.header-section {
  display: flex;
  flex: 1;
  min-width: -webkit-min-content;
}

.tl-dynamic-balance-container {
  cursor: pointer;
  width: max-content;
}

.tl-dynamic-balance-container:hover {
  opacity: 80%;
}

.tl-header {
  background: none;
  transition: all .3s ease;
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: var(--tl-font-family);
  padding: 0 15px;
}

.tl-header.scrolled {
  background: #141d21;
  box-shadow: rgb(6 7 8) 0px 1px 10px;
}

.tl-layout {
  height: 100vh;
  overflow: overlay;
  background-color: none;
  background-image: url("img/bg.jpeg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: darken;
  transition: all .3s ease;
}

.tl-layout.normal {
  background-color: rgba(0, 0, 0, 0.1);

}

.tl-layout.darken {
  background-color: rgba(0, 0, 0, 0.3);
}

.tl-footer-text {
  display: block;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  font-size: 12px;
  margin-top: var(--tl-footer-height);
  line-height: var(--tl-footer-height);
}

.tl-footer-text {
  color: rgb(116 128 151);
  background: #1d2632;
}

.tl-wallet-icon {
  align-self: center;
  fill: rgb(40, 13, 95);
  flex-shrink: 0;
  margin-bottom: 8px;
  height: 30px;
  transition: transform 0.3s ease-in-out;
}

.tl-footer-text.ant-typography>a.tl-social-link {
  filter: invert(0.2);
  color: rgb(116 128 151);
}

.tl-footer-text.ant-typography>a.tl-social-link:hover {
  color: var(--tl-text-color);
  filter: none;
}

.tl-icon-link:hover>img {
  filter: invert(0.2);
}

.tl-discord-icon {
  display: initial;
  margin-bottom: 2px;
}

.tl-build-step {
  background-color: var(--tl-card-color);
  border-radius: 1rem;
  padding-left: 0px !important;
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px);
  user-select: none;

  transition: all .5s ease;
}

.tl-build-step:hover {
  background-color: var(--tl-card-color-hover);

  transition: all .5s ease;
}

.tl-build-step.disabled:hover {
  background-color: var(--tl-card-color);

  transition: all .5s ease;
}

.tl-step-page {
  width: 100%;
  height: 600px;
  margin-top: 20px;
  border-radius: 0.5rem;
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px);
}

.tl-builder-list .ant-row {
  max-height: 504px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tl-builder-list.tl-effects-list .ant-row {
  justify-content: center;
}

.tl-builder-list-item {
  margin-top: 16px;
  margin-bottom: 0px;
  width: 280px;
  padding-right: 10px !important;
  border-radius: 1rem;
  border: 1px solid var(--tl-card-border-color) !important;
  padding: 0px;
  cursor: pointer;

  transition: all .3s ease;
  filter: drop-shadow(rgba(0, 0, 0, 0) 3px 3px 2px);
}

.tl-builder-list-item.tl-order-building {
  cursor: move;
}

.tl-builder-list-item.tl-order-building.tl-building-constructed {
  color: rgb(110, 110, 110);
  border: 1px dashed var(--tl-card-border-color) !important;
  cursor: not-allowed;
}

.tl-builder-list-item.tl-order-building.tl-building-in-progress {
  border: 1px dashed var(--tl-in-progress-color) !important;
  cursor: not-allowed;
}

.tl-builder-list-item.tl-selected-list-item {
  background: var(--tl-card-color-hover);
  border: 1px solid var(--tl-accent-color) !important;
  width: 400px;
}

.tl-builder-list-item:hover {
  background: var(--tl-card-color-hover);
  transition: all .3s ease;
  filter: drop-shadow(rgba(0, 0, 0, 0) 3px 3px 2px);
}

.tl-builder-list-item.tl-town.tl-town-in-progress {
  border: 1px dashed var(--tl-in-progress-color) !important;
}

.tl-builder-list-item.tl-town-in-progress.tl-selected-list-item {
  border: 1px solid var(--tl-accent-color) !important;
}

.tl-builder-list-item.tl-town:active {
  transform: scale(.96);
  transition: all 0.1s ease;
}

.tl-builder-list-item.tl-town-effect:active {
  transform: scale(.96);
  transition: all 0.1s ease;
}

.tl-builder-list-item.tl-building:hover {
  border-color: transparent !important;
}

.tl-builder-list-item .ant-avatar {
  width: 50px;
  height: 50px;
  margin: 8px 0px 8px 14px;
}

.tl-builder-list-item.tl-town-effect .ant-avatar {
  margin: 8px 0px 8px 8px;
}

.tl-builder-list-item .ant-list-item-meta-content {
  margin: auto;
}

.tl-builder-list-item .ant-list-item-meta-title {
  color: var(--tl-text-color);
  margin-bottom: 0px;
}

.tl-builder-list-item.tl-order-building.tl-building-constructed .ant-list-item-meta-title {
  color: #989898d9;
}

.tl-builder-list-item.tl-order-building.tl-building-constructed .ant-avatar {
  filter: grayscale(100%);
  opacity: 20%;
}

.tl-builder-building-hover {
  background: rgba(0, 0, 0, 0.8);
  justify-content: space-evenly;
}

.tl-builder-building-button,
.tl-builder-building-button:focus {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  font-size: 16px;
  font-weight: 600;

  filter: drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px);
  transition: all 0.3s ease;
}

.tl-builder-building-button:active {
  transform: scale(.96);
  transition: all 0.1s ease;
}

.tl-builder-building-button.tl-remove {
  color: #a61d24;
  border-color: #a61d24;
}


.tl-builder-building-button.tl-remove:hover {
  color: #ea3039;
  border-color: #ea3039;
}

.tl-builder-building-button.tl-select {
  color: var(--tl-accent-color-hover);
  border-color: var(--tl-accent-color-hover);
}

.tl-builder-building-button.tl-select:hover {
  color: var(--tl-accent-color);
  border-color: var(--tl-accent-color);
}

.tl-builder-badge {
  position: absolute;
  top: -10px;
  right: -8px;
}

.tl-builder-order-badge {
  position: absolute;
  top: -10px;
  left: 36px;
}

.tl-builder-building-button.tl-remove[disabled],
.tl-builder-building-button.tl-remove[disabled]:hover,
.tl-builder-building-button.tl-select[disabled],
.tl-builder-building-button.tl-select[disabled]:hover {
  color: dimgray;
  border-color: dimgray;
}

.tl-builder-building-button.tl-remove[disabled]:active,
.tl-builder-building-button.tl-select[disabled]:active {
  transform: none;
}

.tl-town-effect-type {
  font-weight: 600;
  font-size: 18px;
}

.tl-builder-step-buttons button {
  transition: all 0.1s ease;
}

.tl-builder-step-buttons button:active {
  transform: scale(.96);
  transition: all 0.1s ease;
}

.tl-builder-step-buttons button[disabled]:active {
  transform: none;
}

.tl-send-or-buy-nft-image-clickable {
  border-radius: 1rem;
  transition: all .5s ease;
}

.tl-send-or-buy-nft-image-clickable:active {
  transform: scale(.96);
  transition: all 0.1s ease;
}

.tl-modal-footer-svg-button {
  filter: invert(99%) sepia(3%) saturate(2362%) hue-rotate(40deg) brightness(117%) contrast(76%);
  /* filter: invert(57%) sepia(69%) saturate(493%) hue-rotate(114deg) brightness(96%) contrast(84%) drop-shadow(3px 3px 2px rgba(0, 0, 0, .5)); */
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.tl-modal-footer-svg-button:hover {
  filter: invert(99%) sepia(3%) saturate(2362%) hue-rotate(40deg) brightness(117%) contrast(76%);
  /* filter: invert(31%) sepia(99%) saturate(350%) hue-rotate(113deg) brightness(96%) contrast(98%) drop-shadow(3px 3px 2px rgba(0, 0, 0, .5)); */
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.tl-nft-modal-action-button,
.tl-nft-modal-action-button:focus {
  border: 1px solid rgb(48 64 77 / 75%);
  width: 42px;
  height: 40px;
  padding: 0px;
  margin-left: 0px;
  color: var(--tl-text-color);
}

.tl-nft-modal-action-button:hover {
  background-color: rgb(48 64 77);
  border: 1px solid rgb(48 64 77);
  color: var(--tl-text-color);
}

.tl-shadow {
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px);
}

.tl-shadow-on-hover:hover {
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px);
}

.tl-shadow-extra {
  filter: drop-shadow(rgba(0, 0, 0, 1) 3px 3px 2px);
}

.tl-shadow-extra-on-hover:hover {
  filter: drop-shadow(rgba(0, 0, 0, 1) 3px 3px 2px);
}

div:hover>.tl-wallet-icon {
  transform: scale(1.2);
}

div.display-balance {
  animation: 0.2s ease 0s 1 normal none running zoomIn;
}

div.hide-balance {
  animation: 0.2s ease 0s 1 normal none running zoomOut;
}

.bold {
  font-weight: bold;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 0;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.custom-scrollbar ::-webkit-scrollbar {
  width: 18px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #6b7378;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: padding-box;
}

.custom-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #89949a;
  background-clip: padding-box;
  border: 4px solid transparent;
}

.custom-scrollbar ::-webkit-scrollbar-corner {
  background: transparent;
}